// Customise the Sentry feedback widget -
// docs: https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/#customizing-the-widget
const sentryDialogOptions = {
  lang: 'NL',
  title: 'Helaas ging er iets fout',
  subtitle:
    'Ons team is op de hoogte gebracht van deze fout. Als je ons wil helpen deze fout in de toekomst te voorkomen, kun je hier informatie voor ons achterlaten.',
  subtitle2:
    'Als je ervoor kiest om jouw e-mailadres achter te laten, brengen wij je op de hoogte wanneer de fout verholpen is.',
  labelName: 'Uw naam',
  labelEmail: 'Uw e-mail',
  labelComments: 'Wat gebeurde er voordat de foutmelding verscheen?',
  labelClose: 'Sluiten',
  labelSubmit: 'Versturen',
  errorGeneric:
    'Er ging iets mis met het versturen van het formulier. Probeer het alstublieft opnieuw.',
  errorFormEntry: 'Niet alle velden zijn goed ingevuld. Probeer het alstublieft opnieuw.',
  successMessage: 'Wij hebben jouw feedback ontvangen, bedankt!',
};
export { sentryDialogOptions };
