import type { Account, AccountEntitlement } from '@elseu/sdu-evidend-graphql';
import { createTypedContextProvider } from 'providers/createTypedContextProvider';
import { useMemo } from 'react';

export const [useAccount, AccountContextProvider] = createTypedContextProvider<
  Account | undefined
>();

export const useEntitlements = (
  neededEntitlements?: AccountEntitlement[],
): [AccountEntitlement[], boolean] => {
  const account = useAccount();

  const entitlements = useMemo(() => account?.entitlements || [], [account?.entitlements]);
  const hasEntitlements =
    entitlements.filter((entitlement) => neededEntitlements?.includes(entitlement)).length !== 0;

  return [entitlements, hasEntitlements];
};
