import Router, { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useBeforeUnload } from 'react-use';

import { LeavePromptContext } from './LeavePromptContext';
import type { ILeavePromptState } from './types';
import { LeavePromptAction } from './types';

const useBeforeUnloadPrompt = (isEnabled: boolean) => {
  // Only use the beforeunload prompt outside development.
  useBeforeUnload(isEnabled && process.env.NODE_ENV !== 'development');
};

export const useLeavePromptStateContext = () => useContext(LeavePromptContext);

export const useLeavePromptState = () => {
  const initialState: ILeavePromptState = {
    isConfirmed: false,
    isShown: false,
  };

  const reducer = (state: ILeavePromptState, action: LeavePromptAction) => {
    switch (action) {
      case LeavePromptAction.Close:
        return { ...initialState };
      case LeavePromptAction.Confirm:
        return { ...state, isConfirmed: true };
      case LeavePromptAction.Show:
        return { isShown: true, isConfirmed: false };
      default:
        throw new Error();
    }
  };

  return useReducer(reducer, initialState);
};

const useFormDirtyState = (): boolean => {
  const form = useFormContext() as UseFormReturn | null;
  return useMemo(() => !!form?.formState.isDirty, [form]);
};

type IRouteParams = {
  url: string;
  options: any;
};

export const useLeaveConfirmRoute = (isEnabled: boolean) => {
  const router = useRouter();
  const [params, setParams] = useState<IRouteParams | undefined>(undefined);
  const [{ isShown, isConfirmed }, dispatch] = useLeavePromptStateContext();

  // Confirm browser/tab closing.
  useBeforeUnloadPrompt(isEnabled);

  const executeRoute = useCallback(() => {
    if (params) {
      // Redirect to the previously requested url.
      const { url, options } = params;
      router.push(url, undefined, options);
    }
  }, [params, router]);

  // When the route change is confirmed, we perform it again manually.
  useEffect(() => {
    if (isShown && isConfirmed) {
      executeRoute();
    }
  }, [executeRoute, isConfirmed, isShown]);

  // When the route changes, we want to intercept it.
  const onRouteChange = useCallback(
    (url: string, options: Record<string, string>) => {
      // The route change should be prevented.
      if (isEnabled) {
        // Unless we already confirmed the route change.
        if (isShown && isConfirmed) {
          // Close the prompt.
          dispatch(LeavePromptAction.Close);

          // Clear the state.
          setParams(undefined);
          return;
        }

        // Save the details of the requested url for later.
        setParams({ url, options });

        // Display the prompt.
        dispatch(LeavePromptAction.Show);

        // We throw a string explicitly so it doesn't trigger any error boundaries.
        // eslint-disable-next-line no-throw-literal
        throw 'Protected unsaved form';
      }
    },
    [dispatch, isConfirmed, isEnabled, isShown, setParams],
  );

  // Register route change handler.
  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChange);

    return () => {
      Router.events.off('routeChangeStart', onRouteChange);
    };
  }, [onRouteChange]);
};

type IHandler = (...args: any[]) => any;

export const useLeaveConfirmCallback = (isEnabled: boolean, handler: IHandler) => {
  const [args, setArgs] = useState<any>(undefined);
  const [{ isShown, isConfirmed }, dispatch] = useLeavePromptStateContext();

  // Confirm browser/tab closing.
  useBeforeUnloadPrompt(isEnabled);

  // When the route change is confirmed, we perform it again manually.
  useEffect(() => {
    // When the prompt was shown and an action was selected.
    if (isShown && isConfirmed) {
      // Call the handler with the original arguments.
      handler(...args);

      // Close the prompt.
      dispatch(LeavePromptAction.Close);

      // Clear the state.
      setArgs(undefined);
    }
  }, [args, dispatch, handler, isConfirmed, isShown]);

  // When the handler is called, we want to intercept it.
  return useCallback(
    (...args: any[]) => {
      // The callback should be prevented.
      if (isEnabled) {
        // Unless we already confirmed the route change.
        if (isShown && isConfirmed) {
          return handler(...args);
        }

        // Save the details of the requested url for later.
        setArgs(args);

        // Display the prompt.
        dispatch(LeavePromptAction.Show);
      } else {
        return handler(...args);
      }
    },
    [dispatch, handler, isConfirmed, isEnabled, isShown, setArgs],
  );
};

export const useFormLeaveConfirmRoute = () => {
  const isDirty = useFormDirtyState();
  return useLeaveConfirmRoute(isDirty);
};

export const useFormLeaveConfirmCallback = (handler: IHandler) => {
  const isDirty = useFormDirtyState();
  return useLeaveConfirmCallback(isDirty, handler);
};
