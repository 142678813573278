import { ErrorIcon, spacing, StatusMessage } from '@elseu/sdu-titan';
import { GlobalErrorMessages } from 'components/GlobalError/GlobalErrorMessages';
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalError } from 'hooks/useGlobalError';
import React from 'react';
import styled from 'styled-components';

// Ported from Titan until we can have animated floating elements.
// https://github.com/elseu/sdu-titan/blob/develop/src/animations/slideFade.ts#L26
const slideFadeBottom = {
  initial: { y: '40px', opacity: 0 },
  animate: { y: '0', opacity: 1 },
  exit: { y: '40px', opacity: 0 },
};

const Floating = styled(motion.div)`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: ${spacing(6)};

  width: 100%;
  max-width: 640px;

  z-index: 1000000;
`;

export const GlobalError = () => {
  const [errors, setError] = useGlobalError();

  return (
    <AnimatePresence>
      {errors && (
        <Floating {...slideFadeBottom}>
          <StatusMessage
            defaultIsShown
            isCloseable
            isRounded
            isShown
            alignment="left"
            icon={<ErrorIcon />}
            type="danger"
            onClose={() => setError(undefined)}
          >
            {typeof errors === 'string' ? errors : <GlobalErrorMessages errors={errors} />}
          </StatusMessage>
        </Floating>
      )}
    </AnimatePresence>
  );
};
