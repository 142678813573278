import { t } from '@lingui/macro';
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: () => t`Invoer is niet geldig`,
    defined: () => t`Dit veld is verplicht`,
    notType: () => t`Invoer is niet geldig`,
    required: () => t`Dit veld is verplicht`,
  },
  date: {
    min: () => t`Datum mag niet leeg zijn`,
    max: () => t`Datum moet in het verleden zijn`,
  },
  number: {
    min: ({ min }) => t`Dit veld moet minimaal ${min} zijn`,
    moreThan: ({ more }) => t`Dit veld moet meer dan ${more} zijn`,
    max: ({ max }) => t`Dit veld moet maximaal ${max} zijn`,
    integer: () => t`Dit veld moet een geheel getal zijn`,
  },
  array: {
    min: ({ min }) => t`Dit veld moet minimaal ${min} element(en) bevatten`,
    max: ({ max }) => t`Dit veld mag maximaal ${max} element(en) bevatten`,
  },
  string: {
    length: ({ length }) => t`Dit veld moet exact ${length} karakter(s) zijn`,
    min: ({ min }) => t`Dit veld moet minimaal ${min} karakter(s) zijn`,
    max: ({ max }) => t`Dit veld mag maximaal ${max} karakter(s) zijn`,
    matches: ({ regex }) => t`Dit veld moet voldoen aan patroon "${regex}"`,
    email: () => t`Dit is geen geldig e-mailadres`,
    url: () => t`Dit is geen geldig adres`,
    uuid: () => t`Dit is geen geldige uuid`,
    trim: () => t`Dit is geen geldige waarde`,
    lowercase: () => t`De waarde mag alleen kleine letters bevatten`,
    uppercase: () => t`De waarde mag alleen hoofdletters bevatten`,
  },
});
