import { Box } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import React from 'react';

/**
 * Generates the user-readable global error message.
 */
export const GlobalErrorMessages = ({
  errors,
}: {
  errors: Array<{ key: string; message: string }>;
}) => {
  if (errors.length > 1) {
    return (
      <Box pl={2}>
        <Trans>De ingevulde gegevens bevatten de volgende fout(en):</Trans>
        <ul>
          {errors.map(({ key, message }) => (
            <li key={key}>{message}</li>
          ))}
        </ul>
      </Box>
    );
  }
  if (errors.length === 1) {
    return <Box pl={2}>{errors[0].message}</Box>;
  }
  return <Trans>Sommige velden bevatten een waarde die niet wordt geaccepteerd.</Trans>;
};
