import { i18n } from '@lingui/core';
import countries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesNL from 'i18n-iso-countries/langs/nl.json';
import { messages as enMessages } from 'locales/en-gb/messages';
import { messages as nlMessages } from 'locales/nl/messages';

i18n.load(
  'en-gb',
  enMessages as any,
); /** figure out if we should care about the type error this generates? */

i18n.load('nl', nlMessages as any);

i18n.activate('nl');

// Register country translations
countries.registerLocale(countriesNL);
countries.registerLocale(countriesEN);
