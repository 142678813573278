import { Loader } from '@elseu/sdu-titan';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

interface IPageLoader {
  hasBackground?: boolean;
  isAnimated?: boolean;
  testId?: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const LoaderContainer = styled.div<IPageLoader>`
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  ${({ hasBackground, isAnimated }) =>
    hasBackground &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      height: 100%;
      transform: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.6);
      z-index: 2147483649; // higher than cookie bar

      ${isAnimated &&
      css`
        opacity: 0;
        animation: ${fadeIn} 0.3s ease 0.5s forwards;
      `}
    `}
`;

const PageLoaderComponent = ({
  hasBackground = false,
  testId = 'pageLoader',
  isAnimated = false,
}: IPageLoader) => {
  return (
    <LoaderContainer data-test-id={testId} hasBackground={hasBackground} isAnimated={isAnimated}>
      <Loader height={80} variant="spinner" />
    </LoaderContainer>
  );
};

const PageLoader = React.memo(PageLoaderComponent);

export { PageLoader };
