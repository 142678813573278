import 'dayjs/locale/nl';
import 'forms/helpers/dateTransform';
import 'site/googleTranslateFix';
import 'site/translations';
import 'regenerator-runtime/runtime';

import { ApolloProvider } from '@apollo/client';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ThemeProvider as TitanThemeProvider } from '@elseu/sdu-titan';
import * as Sentry from '@sentry/nextjs';
import { ErrorPage } from 'components/Error/ErrorPage/ErrorPage';
import { GlobalError } from 'components/GlobalError/GlobalError';
import { LeavePromptProvider } from 'components/LeavePrompt';
import { config } from 'config';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { sentryDialogOptions } from 'helpers/sentryDialogOptions';
import { useApollo } from 'hooks/useApollo';
import { useAuthClientUrl } from 'hooks/useAuthClientUrl';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { OidcJwtProvider } from 'oidc-jwt-client';
import { WithAuth } from 'providers/WithAuth';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme/theme';
import type { CustomPage } from 'types/page';

import { GlobalSiteStyling } from '../theme/GlobalSiteStyling';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

type WithProps = {
  children: JSX.Element;
};
const WithGTMProvider = ({ children }: WithProps) => {
  if (config.gtmId) {
    const state = {
      id: config.gtmId,
    };

    return <GTMProvider state={state}>{children}</GTMProvider>;
  }

  return children;
};

const WithSentry = ({ children }: WithProps) => {
  if (config.sentryOptions) {
    return (
      <Sentry.ErrorBoundary
        showDialog
        dialogOptions={sentryDialogOptions}
        fallback={<ErrorPage isFullScreen brandName={config.brandName} />}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }

  return children;
};

function useRouterReady() {
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsReady(router.isReady);
  }, [router.isReady]);

  return isReady;
}

const WithApollo = ({ children }: WithProps) => {
  const client = useApollo();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

type CustomAppProps = AppProps & {
  Component: CustomPage;
};

const CustomApp = ({ Component, pageProps }: CustomAppProps) => {
  const isRouterReady = useRouterReady();
  const isAuthRequired = !Component.allowUnauthorized;
  const { clientUrl } = useAuthClientUrl();

  if (!isRouterReady) return null;
  return (
    <WithGTMProvider>
      <OidcJwtProvider
        client={{ url: clientUrl }}
        shouldAttemptLogin={false}
        shouldMonitorAccessTokens={true}
      >
        <WithApollo>
          <TitanThemeProvider>
            <ThemeProvider theme={theme}>
              <WithSentry>
                <WithAuth isRequired={isAuthRequired}>
                  <LeavePromptProvider>
                    <GlobalSiteStyling />
                    <GlobalError />
                    <main id="content">
                      <Component {...pageProps} />
                    </main>
                  </LeavePromptProvider>
                </WithAuth>
              </WithSentry>
            </ThemeProvider>
          </TitanThemeProvider>
        </WithApollo>
      </OidcJwtProvider>
    </WithGTMProvider>
  );
};

// The `config` module in this application uses `publicRuntimeConfig` for its values.
// The documentation of Next JS states the following regarding its use:
//   A page that relies on `publicRuntimeConfig` *must* use `getInitialProps` to
//   opt-out of _Automatic Static Optimization_. Runtime configuration won't be
//   available to any page (or component in a page) without `getInitialProps`.
// https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
CustomApp.getInitialProps = () => {
  return { pageProps: {} };
};

export default CustomApp;
