import type { BrowserOptions } from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

// When adding a new config option here, be sure to also add it to:
// - `.env.development`
// - `.github/cypress.env`
// - `.github/workflows/build_test_deploy.yml` (if the value is environment specific).
// - `.docker/generateConfig.bash`
// - `deploy/site/values.yml` (section `site`)
// - `next.config.js`
export interface SiteConfigurationConfig {
  apiUrl: string;
  authUrl: string;
  authEvidendUrl: string;
  brandName: string;
  featureFlags: string[];
  frontOfficeUrl: string;
  gtmId?: string;
  isSSR?: boolean;
  sentryOptions?: BrowserOptions;
}

export const config: SiteConfigurationConfig = publicRuntimeConfig;
