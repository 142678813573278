import queryString from 'query-string';

function stripFromUrl(href: string, ...properties: string[]): string {
  const parsedUrl = queryString.parseUrl(href, { parseFragmentIdentifier: true });
  const { url, query, fragmentIdentifier } = parsedUrl;
  properties.forEach((property) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete query[property];
  });
  return (
    queryString
      .stringifyUrl({ url, query, fragmentIdentifier })
      // replace hack for sometimes appearing # or ? in url
      .replace(/\?$/, '')
      .replace(/#\.$/, '')
  );
}

export { stripFromUrl };
