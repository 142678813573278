import { config } from 'config';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useCookie } from 'react-use';

export const useAuthClientUrl = () => {
  const router = useRouter();
  const { magic_link } = router.query;
  const [magicLink] = useCookie('magic_link');

  return useMemo(() => {
    const clientUrl = magicLink || magic_link ? config.authEvidendUrl : config.authUrl;
    return { clientUrl };
  }, [magicLink, magic_link]);
};
