import { createContext, useContext } from 'react';

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
function createTypedContextProvider<A extends { [k in keyof A]: A[k] } | null>() {
  const ctx = createContext<A | undefined>(undefined);
  function useContextHook() {
    const c = useContext(ctx);
    return c as A;
  }
  return [useContextHook, ctx.Provider] as const;
}

export { createTypedContextProvider };
