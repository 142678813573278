import { config } from 'config';
import { createApolloClient } from 'helpers/apolloClient';
import { useAuthAccessToken } from 'oidc-jwt-client';
import { useMemo } from 'react';

export const useApollo = () => {
  const fetchToken = useAuthAccessToken();
  const uri = `${config.apiUrl}/graphql`;
  return useMemo(() => createApolloClient(uri, fetchToken), [fetchToken, uri]);
};
