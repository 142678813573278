import { ContentWrapper, Header, Link, Logo, StyledHTML } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import React from 'react';
import styled from 'styled-components';

interface IErrorPage {
  isFullScreen?: boolean;
  brandName?: string;
}

const StyledContentWrapper = styled(ContentWrapper)`
  ${({ theme }) => `
    padding-top: ${theme.components.header.paddingVertically};
    padding-bottom: ${theme.components.header.paddingVertically};
  `}
`;

function forceReload() {
  window.location.href = '/';
  window.location.reload();
}

const ErrorPage: React.FC<IErrorPage> = ({ isFullScreen, brandName }) => {
  return (
    <>
      <StyledContentWrapper id="main-content" maxWidth={640} spaceAfter={20} testId="notFound">
        {isFullScreen && brandName && <Logo brandName={brandName} onClick={forceReload} />}
        <Header hasContentWrapper={false} title={t`Helaas ging er iets fout`} />
        <StyledHTML spaceAfter={4}>
          <Trans>
            Het lijkt erop dat er onverwacht iets fout is gegaan en deze fout is bekend bij ons.
            Probeer de pagina <Link onClick={() => window.location.reload()}>opnieuw te laden</Link>{' '}
            of probeer later de pagina opnieuw te openen. Je kan ook contact met ons opnemen via de
            feedback knop rechts in het scherm, of telefonisch via{' '}
            <Link to="tel:0703789880">070-378 9880</Link>
          </Trans>
        </StyledHTML>
      </StyledContentWrapper>
    </>
  );
};

export { ErrorPage };
