// Workaround for when Chrome Translate is used.
// https://github.com/facebook/react/issues/11538
// https://github.com/santiment/app/pull/1179/files

if (typeof Node === 'function') {
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-expect-error Type 'Node' is not assignable to type 'T'
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      return child;
    }
    return originalRemoveChild.apply(this, [child]);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // @ts-expect-error Type 'Node' is not assignable to type 'T'
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      return newNode;
    }
    return originalInsertBefore.apply(this, [newNode, referenceNode]);
  };
}

export {};
