import { scrollbarStyle } from '@elseu/sdu-titan';
import { createGlobalStyle } from 'styled-components';

const GlobalSiteStyling = createGlobalStyle`
  body {
    margin: 0;
    padding-bottom: 80px;
    ${scrollbarStyle.pageScrollbar};
    overflow-y: auto;
  }

  // TODO: Workaround for ActionMenu being clipped by TableContainer.
  div[data-test-id="table"] {
    overflow-x: visible;
  }

  strong {
    font-weight: 600;
  }
`;

export { GlobalSiteStyling };
