import { gql } from '@apollo/client';

import { NAME_FRAGMENT } from '../fragments';

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFields on Account {
    user {
      id
      email
      name {
        ...NameFields
      }
    }
    locale
    entitlements

    isAdmin
  }
  ${NAME_FRAGMENT}
`;

export const ACCOUNT_QUERY = gql`
  query AccountQuery {
    account {
      ...AccountFields
    }
  }
  ${ACCOUNT_FRAGMENT}
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($account: AccountInput!) {
    updateAccount(account: $account) {
      ...AccountFields
    }
  }
  ${ACCOUNT_FRAGMENT}
`;
