import { Block, ContentWrapper, Logo, Paywall } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { config } from 'config';
import { useAuthControls } from 'oidc-jwt-client';
import React from 'react';

export const PaywallUnknownUser = () => {
  const { authorize } = useAuthControls();

  const text =
    `<p>` +
    `${t`Evidend is het aandeelhoudersregister van de 21e eeuw.`}${t`Geïnteresseerd in een abonnement? Neem dan contact op.`}<br />` +
    `<a href="mailto:evidend@sdu.nl">evidend@sdu.nl</a>` +
    `</p>` +
    `<p>` +
    `<strong>${t`Ik heb al een abonnement`}</strong>` +
    `<br />${t`Mogelijk ben je nu op een ander account ingelogd, bijvoorbeeld een algemeen account van je organisatie.`} ${t`Hieronder kun je inloggen met je Evidend-account.`}` +
    `</p>`;

  const buttons = [
    {
      label: 'Opnieuw inloggen',
      onClick: () => authorize({ prompt: 'login', acr_values: 'urn:els:auth:usernamepwd' }),
    },
  ];

  return (
    <ContentWrapper maxWidth={640} spaceAfter={20}>
      <Block py={12}>
        <Logo brandName={config.brandName} />
      </Block>

      <Paywall buttons={buttons} text={text} title={t`Je hebt nog geen abonnement voor Evidend`} />
    </ContentWrapper>
  );
};
