import { rem } from '@elseu/sdu-titan';

const theme: { maxParagraphWrapperWidth: string; colors: { categorical: string[] } } = {
  maxParagraphWrapperWidth: rem(572),
  colors: {
    categorical: [
      '#0095DB',
      '#2E247A',
      '#F59C00',
      '#FFDD00',
      '#86BC25',
      '#E82E8A',
      '#A7358C',
      '#CBCACA',
    ],
  },
};

export { theme };
