import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import introspectionResult from '@elseu/sdu-evidend-graphql';
import { withScalars } from 'apollo-link-scalars';
import { createUploadLink } from 'apollo-upload-client';
import type { IntrospectionQuery } from 'graphql';
import { buildClientSchema, GraphQLScalarType } from 'graphql';
import type { useAuthAccessToken } from 'oidc-jwt-client';

const useIncomingArray = <B extends unknown[] = []>(_: unknown, incoming: B[] = []) => incoming;

const omitTypename = <K extends string, V>(key: K, value: V) =>
  key === '__typename' ? undefined : value;
/**
 * We omit the typename by default, because mutations don't expect the typename to be included.
 * To add back the typename, use the `includeTypename` context option.
 */
const createOmitTypenameLink = () => {
  return new ApolloLink((operation, forward) => {
    if (operation.getContext().includeTypename) return forward(operation);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    return forward(operation);
  });
};

export const createApolloClient = (
  uri: string,
  fetchToken: ReturnType<typeof useAuthAccessToken>,
) => {
  const omitTypenameLink = createOmitTypenameLink();

  const httpLink = createUploadLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await fetchToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  // Apollo-client has no built-in support for custom scalars, so we are using
  // apollo-link-scalars to introduce Date and DateTime as custom scalars.
  // See https://github.com/apollographql/apollo-client/issues/8857
  const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

  const typesMap = {
    Date: new GraphQLScalarType({
      name: 'Date',
      serialize: (parsed?: Date) => parsed?.toISOString().substring(0, 10),
      parseValue: (raw: any) => raw && new Date(raw),
    }),
    DateTime: new GraphQLScalarType({
      name: 'DateTime',
      serialize: (parsed?: Date) => parsed?.toISOString(),
      parseValue: (raw: any) => raw && new Date(raw),
    }),
    URL: new GraphQLScalarType({
      name: 'URL',
      serialize: (parsed: URL) => parsed.toString(),
      parseValue: (raw: any) => new URL(raw),
    }), // few
  };
  const link = ApolloLink.from([
    authLink,
    withScalars({ schema, typesMap }),
    omitTypenameLink,
    httpLink,
  ]);

  // When we receive a new array, always replace the existing array.
  const replace = (_existing: any[], incoming: any[]) => incoming;

  return new ApolloClient({
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        ShareSeries: {
          // The ['id'] item indicates that the id field of the previous field in the array (shareType) is part of the cache ID.
          keyFields: ['from', 'to', 'shareType', ['id']],
        },
        ShareBatch: {
          keyFields: ['shareSeries', ['from', 'to', 'shareType', ['id']]],
        },
        Document: {
          merge: true,
          fields: {
            labels: {
              merge: useIncomingArray,
            },
          },
        },
        RegisterMutation: {
          merge: true,
          fields: {
            administration: {
              merge: useIncomingArray,
            },
            conversion: {
              merge: useIncomingArray,
            },
            endAdministration: {
              merge: useIncomingArray,
            },
            endPledge: {
              merge: useIncomingArray,
            },
            endUsufruct: {
              merge: useIncomingArray,
            },
            incorporation: {
              merge: useIncomingArray,
            },
            issuance: {
              merge: useIncomingArray,
            },
            onboarding: {
              merge: useIncomingArray,
            },
            payingUp: {
              merge: useIncomingArray,
            },
            pledge: {
              merge: useIncomingArray,
            },
            transfer: {
              merge: useIncomingArray,
            },
            usufruct: {
              merge: useIncomingArray,
            },
            withdrawal: {
              merge: useIncomingArray,
            },
          },
        },
        Register: {
          merge: true,
          fields: {
            mutations: {
              merge: useIncomingArray,
            },
            documents: {
              merge: useIncomingArray,
            },
          },
        },
        Query: {
          fields: {
            invitations: { merge: replace },
          },
        },
      },
    }),
  });
};
