import 'dayjs/locale/nl';
import 'forms/helpers/dateTransform';
import 'site/googleTranslateFix';
import 'site/translations';
import 'site/yupTranslations';

import { useLazyQuery } from '@apollo/client';
import type { Account } from '@elseu/sdu-evidend-graphql';
import { AccountEntitlement, Locale } from '@elseu/sdu-evidend-graphql';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { PageLoader } from 'components/PageLoader/PageLoader';
import { PaywallUnknownUser } from 'components/PaywallUnknownUser/PaywallUnknownUser';
import { ACCOUNT_QUERY } from 'graphql/queries/account';
import { stripFromUrl } from 'helpers/stripFromUrl';
import { AccountContextProvider, useEntitlements } from 'hooks/useAccount';
import { useRouter } from 'next/router';
import {
  useAuthAccessClaims,
  useAuthControls,
  useAuthInitialized,
  useAuthIsLoggedIn,
} from 'oidc-jwt-client';
import type { Params } from 'oidc-jwt-client/dist/types/types';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useCookie } from 'react-use';
import type { AccountQuery } from 'types/graphql/AccountQuery';
interface WithAuthProps {
  children: ReactNode;
  isRequired?: boolean;
}

const useAuthorize = () => {
  const { authorize } = useAuthControls();
  const [, setMagicLink] = useCookie('magic_link');
  return useCallback(
    (params: Params = {}) => {
      let values: Params = {
        acr_values: 'urn:els:auth:usernamepwd',
        ...params,
      };
      if (params.magicLink) {
        setMagicLink(params.magicLink);
        const redirect_uri = stripFromUrl(window.location.href, 'token', 'magic_link');
        values = {
          ...values,
          magic_link: params.magicLink,
          redirect_uri,
        };
      }
      authorize(values);
    },
    [authorize, setMagicLink],
  );
};

export const WithAuth = ({ children, isRequired }: WithAuthProps) => {
  const isLoggedIn = useAuthIsLoggedIn();
  const isInitialized = useAuthInitialized();
  const [accountQuery] = useLazyQuery<AccountQuery>(ACCOUNT_QUERY);
  const { value: claims } = useAuthAccessClaims() as { value?: { anon?: boolean } };
  const router = useRouter();
  const { pingPrompt, magic_link } = router.query;
  const [magicLink, setMagicLink] = useCookie('magic_link');
  const authorize = useAuthorize();

  // Determine if the current user is logged in anonymously
  const isAnonymous = claims?.anon;

  // Contains the current user details.
  const [account, setAccount] = useState<Account | undefined>(undefined);

  // Update the user details from the backend.
  useEffect(() => {
    if (isLoggedIn && !account) {
      accountQuery({
        onCompleted: ({ account }) => {
          if (account) {
            setAccount(account);
          }
        },
      });
    }
    if (account) {
      const localeString = account.locale === Locale.ENGLISH_BRITISH ? 'en-gb' : 'nl';
      i18n.activate(localeString);
    }
  }, [accountQuery, account, isLoggedIn]);

  useEffect(() => {
    // Authentication is still initializing
    if (!isInitialized) {
      return;
    }

    if (typeof magic_link === 'string' && magicLink !== magic_link) {
      authorize({ magicLink: magic_link });
      return;
    }

    // Not logged in? Start the authentication process.
    if (!isLoggedIn) {
      if (!isRequired) return;

      // Allow Cypress to use EmbeddedLogin and bypass the Ping prompt.
      if (pingPrompt === 'none') {
        authorize({ prompt: 'none' });
      } else if (magicLink) {
        authorize({ magicLink });
      } else {
        authorize();
      }

      return;
    }

    // Logged in but anonymous? Start the authentication process.
    if (isAnonymous) {
      if (!isRequired) return;
      authorize({ prompt: 'login', acr_values: 'urn:els:auth:usernamepwd' });
    }
  }, [
    authorize,
    isAnonymous,
    isInitialized,
    isLoggedIn,
    isRequired,
    magicLink,
    magic_link,
    pingPrompt,
    setMagicLink,
  ]);

  if (!isInitialized) {
    return <PageLoader />;
  }

  if (!isRequired) {
    return (
      <AccountContextProvider value={account}>
        <I18nProvider i18n={i18n}>{children}</I18nProvider>
      </AccountContextProvider>
    );
  }

  if (!isLoggedIn || isAnonymous) {
    return <PageLoader />;
  }

  if (!account) {
    return <PageLoader />;
  }

  return (
    <AccountContextProvider value={account}>
      <I18nProvider i18n={i18n}>
        <PaywallProvider>{children}</PaywallProvider>
      </I18nProvider>
    </AccountContextProvider>
  );
};

const PaywallProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [, hasEvidendSubscription] = useEntitlements([AccountEntitlement.VIEW_EVIDEND]);

  if (!hasEvidendSubscription) {
    return <PaywallUnknownUser />;
  }
  return <>{children}</>;
};
