import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  StyledHTML,
} from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import React from 'react';

import { useLeavePromptStateContext } from './hooks';
import { LeavePromptAction } from './types';

export const LeavePrompt = () => {
  const [{ isShown }, dispatch] = useLeavePromptStateContext();

  return (
    <Modal
      isShown={isShown}
      size="m"
      zIndex={2000}
      onClose={() => dispatch(LeavePromptAction.Close)}
    >
      <ModalHeader title={t`Wijzigingen niet opgeslagen`} />
      <ModalContent>
        <StyledHTML>
          <p>
            <Trans>Je hebt wijzigingen aangebracht die niet nog niet zijn opgeslagen.</Trans>&nbsp;
            <Trans>
              Weet je zeker dat je dit scherm wil verlaten en de wijzigingen niet wil opslaan?
            </Trans>
          </p>
        </StyledHTML>
      </ModalContent>
      <ModalActions>
        <Button variant="clear" onClick={() => dispatch(LeavePromptAction.Close)}>
          <Trans>Annuleren</Trans>
        </Button>
        <Button variant="danger" onClick={() => dispatch(LeavePromptAction.Confirm)}>
          <Trans>Scherm verlaten</Trans>
        </Button>
      </ModalActions>
    </Modal>
  );
};
