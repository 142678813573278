import { gql } from '@apollo/client';

export const MONEY_FRAGMENT = gql`
  fragment MoneyFields on Money {
    currencyCode
    units
    cents
    precision
  }
`;

export const VALIDATION_ERRORS_FRAGMENT = gql`
  fragment ValidationErrorsFields on ValidationError {
    field
    code
    message
    params {
      name
      value
    }
  }
`;

export const POSTAL_ADDRESS_FRAGMENT = gql`
  fragment PostalAddressFields on PostalAddress {
    streetName
    streetNumber
    streetNumberAddition
    postalCode
    postOfficeBoxNumber
    city
    countryCode

    streetHouseNumber
    postalCodeCity
    region
  }
`;

export const LEGAL_ENTITY_BASE_FRAGMENT = gql`
  fragment LegalEntityFields on LegalEntity {
    id
    name
    incorporatedOn
    legalForm
    isFoundation
    phoneNumber
    emailAddress
    tradeRegisterNumber
    countryOfRegistration
    rsinNumber

    visitorAddress {
      ...PostalAddressFields
    }
    correspondenceAddress {
      ...PostalAddressFields
    }
  }
  ${POSTAL_ADDRESS_FRAGMENT}
`;

export const NAME_FRAGMENT = gql`
  fragment NameFields on Name {
    preposition
    initials
    firstName
    nickname
    lastName
  }
`;

export const ENTITLED_ENTITY_FRAGMENT = gql`
  fragment EntitledEntityFields on EntitledEntity {
    id
    name
    email
    type
    entitlement
    temporaryAccessExpiration
  }
`;

export const ACCESS_REQUEST_FRAGMENT = gql`
  fragment AccessRequestFields on AccessRequest {
    id
    register {
      id
      legalEntity {
        name
        tradeRegisterNumber
      }
    }

    requestedAt
    requestedByGroup {
      id
      name
    }
    requestedByUser {
      id
      email
      name {
        ...NameFields
      }
    }

    entitlement
    reason

    # reminders
  }

  ${NAME_FRAGMENT}
`;

export const INVITATION_FRAGMENT = gql`
  fragment InvitationFields on Invitation {
    id
    email

    register {
      id
      name
      tradeRegisterNumber
    }
    invitedByGroup {
      id
      name
    }

    invitedByUser {
      id
      name {
        ...NameFields
      }
    }

    entitlement
    status

    created
    validUntil
    accepted
  }

  ${NAME_FRAGMENT}
`;

export const NOTARY_FRAGMENT = gql`
  fragment NotaryFields on Notary {
    name {
      ...NameFields
    }
    location
  }
  ${NAME_FRAGMENT}
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    type
    labels
    date
    commentary
    deedPassed {
      notary {
        ...NotaryFields
      }
      notaryRole
      assignedNotary {
        ...NotaryFields
      }
    }
    mutation {
      id
      type
    }
    file {
      name
      mimeType
    }
    canBeRemoved
  }
  ${NOTARY_FRAGMENT}
`;

export const SHARE_TYPE_VERSION_FRAGMENT = gql`
  fragment ShareTypeVersionFields on ShareTypeVersion {
    id
    name

    voting
    dividend
    meeting

    nominalValue {
      currencyCode
      units
      cents
      precision
    }
    classifier
    authorizedCapitalAmount
    createdInMutation {
      id
      effectiveDate
    }
  }
`;

export const SHARE_TYPE_FRAGMENT = gql`
  fragment ShareTypeFields on ShareType {
    id
    versionId
    name

    voting
    dividend
    meeting

    nominalValue {
      currencyCode
      units
      cents
      precision
    }
    classifier
    authorizedCapitalAmount
    createdInMutation {
      id
    }
    endedOn
    versions {
      ...ShareTypeVersionFields
    }
  }
  ${SHARE_TYPE_VERSION_FRAGMENT}
`;

export const SHARE_SERIES_FRAGMENT = gql`
  fragment ShareSeriesFields on ShareSeries {
    from
    to
    shareType {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;

export const NATURAL_PERSON_FRAGMENT = gql`
  fragment NaturalPersonFields on NaturalPerson {
    id
    name {
      ...NameFields
    }
    tag
    dateOfBirth
    dateOfDecease
    placeOfBirth

    postalAddress {
      ...PostalAddressFields
    }

    phoneNumber
    emailAddress
  }
  ${NAME_FRAGMENT}
  ${POSTAL_ADDRESS_FRAGMENT}
`;

export const PARTICIPATION_FRAGMENT = gql`
  fragment ParticipationsFields on Participation {
    register {
      id
    }

    shareBatches {
      shareSeries {
        from
        to
        shareType {
          id
          nominalValue {
            currencyCode
            units
            cents
            precision
          }
          classifier
          name
        }
      }
    }
    comment
    tags
    participationTypes
    meetingRights {
      startDate
      endDate
    }
    iban
  }
`;

export const PARTY_WITH_PARTICIPATIONS = gql`
  fragment PartyWithParticipationsFields on Party {
    id
    type
    legalEntity {
      name
      tradeRegisterNumber
      isFoundation
      participation {
        ...ParticipationsFields
      }
    }
    naturalPerson {
      name {
        ...NameFields
      }
      tag
      dateOfBirth
      dateOfDecease
      participation {
        ...ParticipationsFields
      }
    }
  }
  ${NAME_FRAGMENT}
  ${PARTICIPATION_FRAGMENT}
`;

export const PARTY_FRAGMENT = gql`
  fragment PartyFields on Party {
    id
    type
    legalEntity {
      name
      tradeRegisterNumber
      isFoundation
    }
    naturalPerson {
      name {
        ...NameFields
      }
      tag
      dateOfBirth
      dateOfDecease
    }
  }
  ${NAME_FRAGMENT}
`;

export const DELEGATION_DETAILS_FRAGMENT = gql`
  fragment DelegationDetailsFields on DelegationDetails {
    delegatee {
      ...PartyFields
    }
    voting
    meeting
    dividend
  }
  ${PARTY_FRAGMENT}
`;

export const MUTATION_FRAGMENT = gql`
  fragment MutationFields on RegisterMutation {
    id
    type

    title
    commentary

    effectiveDate
    acknowledgedDate

    createdOn

    lastUpdatedOn
    lastUpdatedBy {
      id
      email
    }

    seq
    isDraft
    editable

    validationErrors {
      ...ValidationErrorsFields
    }

    administration {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        administrator {
          ...PartyFields
        }
        endsAt
      }
    }
    attachment {
      owner {
        ...PartyFields
      }
      type
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        bailiff {
          ...PartyFields
        }
        requiredBy {
          ...PartyFields
        }
        attachedAt
      }
    }
    conversion {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      action
      paid
    }
    endAdministration {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
    }
    endAttachment {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        bailiff {
          ...PartyFields
        }
        attachedAt
      }
    }
    endPledge {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        ...DelegationDetailsFields
      }
    }
    endUsufruct {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        ...DelegationDetailsFields
      }
    }
    incorporation {
      to {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      paid
    }
    issuance {
      to {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      paid
    }
    liquidation {
      reason
    }
    onboarding {
      to {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      paid
    }
    payingUp {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
    }
    pledge {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        ...DelegationDetailsFields
      }
    }
    transfer {
      to {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      from {
        ...PartyFields
      }
    }
    usufruct {
      owner {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
      details {
        ...DelegationDetailsFields
      }
    }
    withdrawal {
      from {
        ...PartyFields
      }
      shareSeries {
        ...ShareSeriesFields
      }
    }
  }
  ${DELEGATION_DETAILS_FRAGMENT}
  ${PARTY_FRAGMENT}
  ${NAME_FRAGMENT}
  ${SHARE_SERIES_FRAGMENT}
  ${VALIDATION_ERRORS_FRAGMENT}
`;

export const MUTATION_WITH_DOCUMENT_FRAGMENT = gql`
  fragment MutationWithDocumentFields on RegisterMutation {
    ...MutationFields
    document {
      ...DocumentFields
    }
  }
  ${MUTATION_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`;

export const SHARE_BATCH_FRAGMENT = gql`
  fragment ShareBatchFields on ShareBatch {
    paid
    shareholder {
      ...PartyFields
    }
    modifiedBy {
      id
      type
      title
      effectiveDate
    }
    acquiredBy {
      id
      type
      title
      effectiveDate
    }

    pledgeDetails {
      createdBy {
        id
        type
        title
        effectiveDate
      }
      delegatee {
        ...PartyFields
      }
      voting
      meeting
      dividend
    }
    usufructDetails {
      createdBy {
        id
        type
        title
        effectiveDate
      }
      delegatee {
        ...PartyFields
      }
      voting
      meeting
      dividend
    }
    administrationDetails {
      createdBy {
        id
        type
        title
        effectiveDate
      }
      administrator {
        ...PartyFields
      }
      endsAt
    }
    attachmentDetails {
      createdBy {
        id
        type
        title
        effectiveDate
      }
      bailiff {
        ...PartyFields
      }
      requiredBy {
        ...PartyFields
      }
      attachedAt
    }

    shareSeries {
      ...ShareSeriesFields
    }
  }
  ${PARTY_FRAGMENT}
  ${SHARE_SERIES_FRAGMENT}
`;

const REGISTER_SIGNATURE_FRAGMENT = gql`
  fragment RegisterSignatureFields on RegisterSignature {
    id
    user {
      id
      email
      name {
        ...NameFields
      }
    }
    signedOn
  }
  ${NAME_FRAGMENT}
`;

export const REGISTER_FRAGMENT = gql`
  fragment RegisterFields on Register {
    id
    legalEntity {
      ...LegalEntityFields
    }
    replacement
    endedOn
    effectiveDate
    articleOfAssociationUpdatedOn
    trustConditionsUpdatedOn
    requestForAccess {
      id
    }

    shareTypes {
      ...ShareTypeFields
    }

    shareBatches {
      ...ShareBatchFields
    }

    shareholders {
      ...PartyWithParticipationsFields
    }

    mutations {
      ...MutationFields
    }

    documents {
      ...DocumentFields
    }

    entitlement
    isSigned
    signatures {
      ...RegisterSignatureFields
    }
    lastUpdatedOn
  }
  ${SHARE_BATCH_FRAGMENT}
  ${PARTY_WITH_PARTICIPATIONS}
  ${DOCUMENT_FRAGMENT}
  ${MUTATION_FRAGMENT}
  ${LEGAL_ENTITY_BASE_FRAGMENT}
  ${REGISTER_SIGNATURE_FRAGMENT}
`;

export const PARTY_UPDATE_FRAGMENT = gql`
  fragment PartyUpdateFields on PartyUpdate {
    id
    party {
      ...PartyFields
    }

    field
    before
    after

    requiresDocument

    date
    document {
      ...DocumentFields
    }
    reason
  }
  ${PARTY_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${NOTARY_FRAGMENT}
`;
